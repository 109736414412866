// Generated by Framer (33d5032)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["N0iVYa7tz"];

const serializationHash = "framer-3lk5v"

const variantClassNames = {N0iVYa7tz: "framer-v-1kjl88h"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, imageRadius, width, ...props}) => { return {...props, NT9eU2jZX: image ?? props.NT9eU2jZX ?? {src: "https://framerusercontent.com/images/WTxZwcNinFyvtSQa7ZuKUdGsOCA.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/WTxZwcNinFyvtSQa7ZuKUdGsOCA.png?scale-down-to=512 512w,https://framerusercontent.com/images/WTxZwcNinFyvtSQa7ZuKUdGsOCA.png 800w"}, tQK9NaPEk: imageRadius ?? props.tQK9NaPEk ?? 10} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};imageRadius?: number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, NT9eU2jZX, tQK9NaPEk, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "N0iVYa7tz", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(NT9eU2jZX)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1kjl88h", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"N0iVYa7tz"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: tQK9NaPEk, borderBottomRightRadius: tQK9NaPEk, borderTopLeftRadius: tQK9NaPEk, borderTopRightRadius: tQK9NaPEk, ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3lk5v.framer-5voshq, .framer-3lk5v .framer-5voshq { display: block; }", ".framer-3lk5v.framer-1kjl88h { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 300px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 400px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-3lk5v.framer-1kjl88h { gap: 0px; } .framer-3lk5v.framer-1kjl88h > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-3lk5v.framer-1kjl88h > :first-child { margin-left: 0px; } .framer-3lk5v.framer-1kjl88h > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 300
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"NT9eU2jZX":"image","tQK9NaPEk":"imageRadius"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerG4uNBSVAA: React.ComponentType<Props> = withCSS(Component, css, "framer-3lk5v") as typeof Component;
export default FramerG4uNBSVAA;

FramerG4uNBSVAA.displayName = "Image";

FramerG4uNBSVAA.defaultProps = {height: 300, width: 400};

addPropertyControls(FramerG4uNBSVAA, {NT9eU2jZX: {__defaultAssetReference: "data:framer/asset-reference,WTxZwcNinFyvtSQa7ZuKUdGsOCA.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, tQK9NaPEk: {defaultValue: 10, title: "Image Radius", type: ControlType.Number}} as any)

addFonts(FramerG4uNBSVAA, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})